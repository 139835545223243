// 调试API
import request from '@/utils/request/index';

import { upload, download } from '@/utils/request/index';

export const getInfo = () => request({
  url: '/users/current-user',
  method: 'GET'
});

export const getUserList = (parameter:Object) => request({
  url: '/users/page',
  method: 'GET',
  params: parameter
});

export const getUserById = (id:any) => request({
  url: `/users/${id}`,
  method: 'GET'
});

export const addUser = (params:Object) => request({
  url: '/users',
  method: 'POST',
  data: params
});

export const updateUser = (id:any, params:Object) => request({
  url: `/users/${id}`,
  method: 'PUT',
  data: params
});

export const deleteUser = (id:any) => request({
  url: `/users/${id}`,
  method: 'DELETE'
});

/* 同步用户 */
export const syncUser = () => request({
    url: `/users/sync`
});

export const getRoleList = (parameter:Object) => request({
  url: '/roles/page',
  method: 'GET',
  params: parameter
});

export const listAvailableRoles = () => request({
  url: '/roles',
  method: 'GET',
  params: {
    status: 'Enabled'
  }
});

export const getRoleById = (id:any) => request({
  url: `/roles/${id}`,
  method: 'GET'
});

export const listApiPermissions = () => request({
  url: '/permissions/api',
  method: 'GET'
});

export const listDataPermissions = () => request({
  url: '/permissions/data',
  method: 'GET'
});

export const synchronizePermissions = () => request({
  url: '/permissions/sync',
  method: 'POST'
});

export const addRole = (params:Object) => request({
  url: '/roles',
  method: 'POST',
  data: params
});

export const updateRole = (roleId: any, params: Object) => request({
  url: `/roles/${roleId}`,
  method: 'PUT',
  data: params
});

export const deleteRole = (roleId: any) => request({
  url: `/roles/${roleId}`,
  method: 'DELETE'
});

export const listGlobalRoleUserTypes = () => request({
  url: '/roles/user-types',
  method: 'GET'
});

export const pagePermissions = (parameter: Object) => request({
  url: '/permissions/page',
  method: 'GET',
  params: parameter
});

export const pageDataPermissions = (parameter: Object) => request({
  url: '/permissions/data/page',
  method: 'GET',
  params: parameter
});

/** 根据id查看权限 */
export const getById = (id: any) => request({
  url: `/permissions/${id}`,
  method: 'GET',
});

/** 根据id删除权限 */
export const deleteById = (id: any) => request({
  url: `/permissions/${id}`,
  method: 'DELETE'
});

/** 编辑权限 */
export const updateById = (id: any, params: Object) => request({
  url: `/permissions/${id}`,
  method: 'PUT',
  data: params
});

/** 添加权限 */
export const save = (params: Object) => request({
  url: `/permissions`,
  method: 'POST',
  data: params
});


/*获取单个部门Id*/
export const getDepartmentById = (id: any) => request({
  url: `/departments/${id}`,
  method: 'GET',
});

/*新增部门信息*/
export const saveDepartment = (params: Object) => request({
  url: `/departments`,
  method: 'POST',
  data: params
});

/*删除一个部门*/
export const deleteDepartment = (id: any) => request({
  url: `/departments/${id}`,
  method: 'DELETE',
});

/*更新一个部门*/
export const updateDepartment = (id: any, params: Object) => request({
  url: `/departments/${id}`,
  method: 'PUT',
  data: params
});

/* 获取部门树*/
export const getDepartmentTree = (params?: Object) => request({
  url: `/departments/list`,
  method: 'GET',
  data: params
});

 /* 同步部门 */
export const syncDepartment = () => request({
   url: `/departments/sync`
});

/*导入功能函数*/
export const uploadExcel = (file:File,queryParams:Record<string, any>) => upload('/users/import', file, queryParams);

/*导出功能函数*/

export const downloadExcel = (params:Record<string, any>, fileName:string) => download('/users/export', params, fileName);


 export const getPartnerList = () => request({//合作伙伴管理信息下拉
    url: '/partners/getPartnerList',
    method: 'GET'
  });