/*
 * @Description: static routes
 */

import { fa } from "element-plus/es/locale";

//! If unnecessary, please don't set name in root, otherwise it will be shown in breadcrumb.

export default [
  {
    root: {},
    meta: { alive: true },
    path: '/',
    hidden: false,
    redirect: '/home',
    component: 'layout',
    permissions: [],
    children: [
      {
        name: 'home',
        meta: { alive: true, fullScreen: true },
        path: '/home',
        hidden: false,
        component: 'home/index.vue',
        permissions: [],
      },
      {
        name: 'system',
        meta: { alive: true },
        path: '/system',
        hidden: false,
        redirect: '/system/hompage',
        component: 'RouterView',
        permissions: [],
        children: [
          {
            name: 'system.homgpage',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: false },
            path: '/system/hompage',
            hidden: false,
            component: 'system/hompage/hompg.vue',
            permissions: [],
          },
          {
            name: 'system.customs',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: true },
            path: '/system/cus',
            component: 'RouterView',
            redirect:'/system/cus/rack',
            permissions: [],
            children: [
              {
                name: 'cus.rack',
                meta: { alive: true},
                path: '/system/cus/rack',
                hidden: false,
                component: 'system/cus/rack/rack.vue',
                permissions: ['rack.query'],
              }
            ],
          },
          {
            name: 'system.dpcard',
            meta: { defaultIcon: 'Discount', activeIcon: 'DelegationFilled', alive: true },
            path: '/system/dpcard',
            component: 'RouterView',
            hidden: false,
            redirect:'/system/dpcard/ledger',
            permissions: [],
            children: [
              {
                name: 'dpcard.ledger',
                meta: { alive: true},
                path: '/system/dpcard/ledger',
                hidden: false,
                component: 'system/dpcard/ledger/LedgerList.vue',
                permissions: ['ledger.query'],
              },
              {
                name: 'dpcard.tocq',
                meta: { alive: true},
                path: '/system/dpcard/tocq',
                hidden: false,
                component: 'system/dpcard/tocq/TocqList.vue',
                permissions: ['tocq.query'],
              }
            ],
          },
          {
            name: 'system.filemg',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: true },
            path: '/system/filemg',
            hidden: false,
            component: 'RouterView',
            redirect:'/system/filemgs/dpFileUpload',
            permissions: [],
            children:[
              {
                name: 'filemg.dpEPart',
                meta: { alive: false},
                path: '/system/filemgs/dpFileUpload',
                hidden: false,
                component: 'system/filemg/dpFileUpload/DpexportpartList.vue',
                permissions: ['dpFileUpload.query'],
              },
              {
                name: 'filemg.dpSeach',
                meta: { alive: true},
                path: '/system/filemgs/dpFileSearch',
                hidden: false,
                component: 'system/filemg/dpFileSearch/DpFileSearchList.vue',
                permissions: ['dpFileSearch.query'],
              }
              // {
              //   name: 'filemg.filemg',
              //   meta: { alive: false },
              //   path: '/system/filemgs/filemg',
              //   hidden: false,
              //   component: 'system/filemg/fileinfo/FilemgList.vue',
              //   permissions: ['filemg.query'],
              // },
              
              
              // {
              //   name: 'filemg.dpFReport',
              //   meta: { alive: false },
              //   path: '/system/filemgs/dpFReport',
              //   hidden: false,
              //   component: 'system/filemg/dpFReport/DpFReportList.vue',
              //   permissions: ['dpFReport.query'],
              // }
            ]
          },
          {
            name: 'system.dpShipNoCertificate',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: true },
            path: '/system/dpShipNoCertificate',
            hidden: false,
            component: 'RouterView',
            redirect:'/system/dpShipNoCertificate/dpShipNotice',
            permissions: [],
            children:[
              {
                name: 'filemg.pdoc',
                meta: { alive: true },
                path: '/system/filemgs/pdoc',
                hidden: false,
                component: 'system/filemg/pdoc/PdocList.vue',
                permissions: ['pdoc.query'],
              },
              {
                name: 'dpShipNoCertificate.dpShipNotice',
                meta: { alive: true},
                path: '/system/dpShipNoCertificate/dpShipNotice',
                hidden: false,
                component: 'system/dpShipNoCertificate/dpShipNotice/DpShipNoticeList.vue',
                permissions: ['dpShipNotice.query'],
              },
              {
                name: 'filemg.dpInspledger',
                meta: { alive: true},
                path: '/system/filemgs/dpInspledger',
                hidden: false,
                component: 'system/filemg/dpInspledger/DpInspledgerList.vue',
                permissions: ['dpInspledger.query'],
              },
              {
                name: 'dpShipNoCertificate.dpGoodsCert',
                meta: { alive: true},
                path: '/system/dpShipNoCertificate/dpGoodsCert',
                hidden: false,
                component: 'system/dpShipNoCertificate/dpGoodsCert/DpGoodsCertList.vue',
                permissions: ['dpGoodsCert.query'],
              },
            ]
          },
          {
            name: 'system.dpexport',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: true },
            path: '/system/dpexport',
            hidden: false,
            component: 'RouterView',
            redirect:'/system/dpexport/dpexportfta',
            permissions: [],
            children:[
              {
                name: 'dpexport.dpEPart',
                meta: { alive: true },
                path: '/system/dpexport/dpexportfta',
                hidden: false,
                component: 'system/dpexport/dpexportfta/DpexportpartList.vue',
                permissions: ['fta.query'],
              },
              // {
              //   name: 'dpexport.dptoconfigure',
              //   meta: { alive: true },
              //   path: '/system/dpexport/dptocontypes',
              //   hidden: false,
              //   component: 'system/dpexport/dptocontypes/LedgerList.vue',
              //   permissions: ['declare-origin-template-type.query'],
              // },
              {
                name: 'dpexport.dptoconadministration',
                meta: { alive: true },
                path: '/system/dpexport/dptocontemplates',
                hidden: false,
                component: 'system/dpexport/dptocontemplates/LedgerList.vue',
                permissions: ['declare-origin-template.query'],
              },
              {
                name: 'dpexport.dptocondeclare',
                meta: { alive: true },
                path: '/system/dpexport/dptocondedocs',
                hidden: false,
                component: 'system/dpexport/dptocondedocs/LedgerList.vue',
                permissions: ['declare-origin-declaration-doc.query'],
              },
              {
                name: 'dpexport.new',
                meta: { alive: true },
                path: '/system/dpexport/certType/:certType/certName/:certName/id/:id',
                hidden: true,
                component: 'system/dpexport/dpexportfta/Dpexadd.vue',
                permissions: ['fta.query'],
              },
              {
                name: 'dpexport.edit',
                meta: { alive: true },
                path: '/system/dpexport/edit/certNo/:certNo/certType/:certType',
                hidden: true,
                component: 'system/dpexport/dpexportfta/Dpexadd.vue',
                permissions: ['fta.query'],
              },
              {
                name: 'dpexport.details',
                meta: { alive: true },
                path: '/system/dpexport/details/certNo/:certNo/certType/:certType',
                hidden: true,
                component: 'system/dpexport/dpexportfta/DpexaddDetails.vue',
                permissions: ['fta.query'],
              },
            ]
          },
          {//合作伙伴
            name: 'system.partnermanagement',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: true },
            path: '/system/partnermanagement',
            hidden: false,
            component: 'RouterView',
            redirect:'/system/partnermanagement/partner',
            permissions: [],
            children:[
              {
                name: 'partnermanagement.partner',
                meta: { alive: false},
                path: '/system/partnermanagement/partner',
                hidden: false,
                component: 'system/partnermanagement/partner/List.vue',
                permissions: ['partners.query'],
              },
            ]
          },
          {//零件管理
            name: 'system.partsmanagement',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: true },
            path: '/system/partsmanagement',
            hidden: false,
            component: 'RouterView',
            redirect:'/system/partsmanagement/parts',
            permissions: [],
            children:[
              {
                name: 'partsmanagement.parts',
                meta: { alive: false},
                path: '/system/partsmanagement/parts',
                hidden: false,
                component: 'system/partsmanagement/parts/List.vue',
                permissions: ['fta-part-management.query'],
              },
              {
                name: 'partsmanagement.partsbom',
                meta: { alive: false},
                path: '/system/partsmanagement/partsbom',
                hidden: false,
                component: 'system/partsmanagement/partsbom/List.vue',
                permissions: ['fta-parts-bom.query'],
              },
              {
                name: 'partsmanagement.details',
                meta: { alive: true },
                path: '/system/parts/details/id/:id',
                hidden: true,
                component: 'system/partsmanagement/parts/Details.vue',
                permissions: ['fta-parts-bom.query'],
              },
              {
                name: 'partsmanagement.detailsbom',
                meta: { alive: true },
                path: '/system/parts/partsbomdetails/id/:id',
                hidden: true,
                component: 'system/partsmanagement/partsbom/Details.vue',
                permissions: ['fta-parts-bom.query'],
              },
            ]
          },
          {//预审信息
              name: 'system.prereview',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: true },
            path: '/system/prereview',
            hidden: false,
            component: 'RouterView',
            redirect:'/system/prereview/administration',
            permissions: [],
            children:[
              {//预审-预审信息管理
                name: 'prereview.administration',
                meta: { alive: false},
                path: '/system/prereview/administration',
                hidden: false,
                component: 'system/prereview/administration/List.vue',
                permissions: ['fta-pre-audit-info-management.query'],
              },
              {//预审信息-bom详情
                name: 'prereview.bometails',
                meta: { alive: true },
                path: '/system/prereview/bomdetails/id/:id',
                hidden: true,
                component: 'system/prereview/administration/BomDetails.vue',
                permissions: ['fta-pre-audit-info-management.query'],
              },
              {//预审信息-历史数据详情
                name: 'prereview.details',
                meta: { alive: true },
                path: '/system/prereview/details/id/:id',
                hidden: true,
                component: 'system/prereview/administration/Datails.vue',
                permissions: ['fta-pre-audit-info-management.query'],
              },
              {//预审信息管理-申请单号维护
                name: 'prereview.maintenancedetails',
                meta: { alive: true },
                path: '/system/prereview/administration/id/:id',
                hidden: true,
                component: 'system/prereview/administration/MaintenanceDetails.vue',
                permissions: ['fta-pre-audit-info-management.query'],
              },
              {//预审-预审关联零件明细
                name: 'prereview.partsdetails',
                meta: { alive: false},
                path: '/system/prereview/prereviewassociation',
                hidden: false,
                component: 'system/prereview/prereviewassociation/List.vue',
                permissions: ['fta-pre-audit-relation-part.query'],
              },
               {//预审-预审关联零件明细BOM
                name: 'prereview.partsdbometails',
                meta: { alive: true},
                path: '/system/prereview/partsdbometails/id/:id',
                hidden: true,
                component: 'system/prereview/prereviewassociation/Details.vue',
                permissions: ['fta-pre-audit-relation-part.query'],
              },
              {//预审-历史预审信息
                name: 'prereview.historicalformation',
                meta: { alive: false},
                path: '/system/prereview/historicalformation',
                hidden: false,
                component: 'system/prereview/historicalformation/List.vue',
                permissions: ['fta-pre-audit-info-management-history.query'],
              },
              {//预审信息-历史预审信息详情
                name: 'prereview.historytails',
                meta: { alive: true },
                path: '/system/prereview/historydetails/id/:id',
                hidden: true,
                component: 'system/prereview/historicalformation/Details.vue',
                permissions: ['fta-pre-audit-info-management-history.query'],
              },
              {//预审信息-历史预审信息bom据详情
                name: 'prereview.historybomtails',
                meta: { alive: true },
                path: '/system/prereview/historybomdetails/id/:id',
                hidden: true,
                component: 'system/prereview/historicalformation/BomDetails.vue',
                permissions: ['fta-pre-audit-info-management-history.query'],
              },
              {//预审-历史预审明细
                name: 'prereview.historicaldetails',
                meta: { alive: false},
                path: '/system/prereview/historicaldetails',
                hidden: false,
                component: 'system/prereview/historicaldetails/List.vue',
                permissions: ['fta-pre-audit-info-management-history.query'],
              },
              {//预审信息-历史预审信息bom据详情
                name: 'prereview.historiesbomtails',
                meta: { alive: true },
                path: '/system/prereview/historiesbomdetails/id/:id',
                hidden: true,
                component: 'system/prereview/historicaldetails/Details.vue',
                permissions: ['fta-pre-audit-info-management-history.query'],
              },
            ]
          },
          {
            name: 'system.psettings',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: true },
            path: '/system/psettings',
            component: 'RouterView',
            hidden: false,
            redirect:'/system/psettings/dppqconfig',
            permissions: [],
            children: [
              {
                name: 'psettings.dppqconfig',
                meta: { alive: true},
                path: '/system/psettings/dppqconfig',
                hidden: false,
                component: 'system/psettings/dppqconfig/DppqconfigList.vue',
                permissions: ['dppqconfig.query'],
              }
            ],
          },
          {//系统设置
            name: 'system.settingstype',
            meta: { defaultIcon: 'Seting', activeIcon: 'SetingFilled', alive: true },
            path: '/system/settings',
            hidden: false,
            redirect: '/system/dpexport/dptocontypes',
            component: 'RouterView',
            permissions: [],
            children: [
              {
                name: 'dpexport.dptoconfigure',
                meta: { alive: true },
                path: '/system/dpexport/dptocontypes',
                hidden: false,
                component: 'system/dpexport/dptocontypes/LedgerList.vue',
                permissions: ['declare-origin-template-type.query'],
              },
              {
                name: 'settingstype.syslog',
                meta: {alive: true },
                path: '/system/settings/syslog',
                hidden: false,
                component: 'system/settingstype/syslog/SysLogList.vue',
                permissions: ['sys-log.query'],
              },
              // {
              //   name: 'settingstype.check',
              //   meta: {alive: true },
              //   path: '/system/settings/check',
              //   hidden: false,
              //   component: 'system/settingstype/check/List.vue',
              //   permissions: ['sys-log.query'],
              // },
            ],
          },
          {
            name: 'system.rbac',
            meta: { defaultIcon: 'Seting', activeIcon: 'SetingFilled', alive: true },
            path: '/system/rbac',
            hidden: false,
            redirect: '/system/rbac/user',
            component: 'RouterView',
            permissions: [],
            children: [
              {
                name: 'rbac.user',
                meta: { alive: true},
                path: '/system/rbac/user',
                hidden: false,
                component: 'system/rbac/user/UserList.vue',
                permissions: ['user.query'],
              },
              {
                name: 'rbac.role',
                meta: { alive: true},
                path: '/system/rbac/role',
                hidden: false,
                component: 'system/rbac/role/RoleList.vue',
                permissions: ['role.query'],
              },
              {
                name: 'rbac.permission',
                meta: { alive: true},
                path: '/system/rbac/permission',
                hidden: false,
                component: 'system/rbac/permission/PermissionList.vue',
                permissions: ['permission.query'],
              },
              {
                name: 'rbac.department',
                meta: { alive: true},
                path: '/system/rbac/department',
                hidden: false,
                component: 'system/rbac/department/DepartmentList.vue',
                permissions: ['department.query'],
              },
            ],
          },
          
          {
            name: 'system.notification',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: true },
            path: '/system/notification',
            hidden: false,
            component: 'system/notification/NotificationList.vue',
            permissions: ['notification.query'],
          },
          {
            name: 'system.dictionary',
            meta: { defaultIcon: 'DataDictionary', activeIcon: 'DataDictionaryFilled', alive: true },
            path: '/system/dict',
            hidden: false,
            component: 'system/dict/DictList.vue',
            permissions: ['dict.query'],
          },
          {
            name: 'system.audit',
            meta: { defaultIcon: 'Audit', activeIcon: 'AuditFilled', alive: true },
            path: '/system/audit',
            hidden: false,
            component: 'system/audit/AuditList.vue',
            permissions: ['audit.query'],
          },
          {
            name: 'system.delegation',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: true },
            path: '/system/delegation',
            hidden: false,
            component: 'system/delegation/DelegationList.vue',
            permissions: ['delegation.query'],
          },
          {
            name: 'system.jobManager',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: true },
            path: '/system/jobManager',
            hidden: false,
            component: 'system/jobManager/JobManagerList.vue',
            permissions: ['job-Manager.query'],
          },
          
 
        ],
      },
      {
        name: 'system.notFound',
        meta: { icon: '' ,alive: true},
        path: '/404',
        hidden: true,
        component: 'exception/404.vue',
        permissions: [],
      },
    ],
  },
];
